import { CardResponse } from 'components/card-domain';
import { DecoratedDigitalCardResponse } from 'components/digital-card-domain';
import { AcpDeviceServiceProvider } from 'core';
import { AcpDigitalWalletApplePay } from './acp-digital-wallet-domain-apple-pay';
import { AcpDigitalWalletGooglePay } from './acp-digital-wallet-domain-google-pay';
import {
  DigitalCardTokenizedResponse,
  DigitalCardTokenRefs,
  CardTokenRefsRequest,
  DigitalCardTokenRefsResponse,
  IsTokenizedResponse,
  TokenRefsRequest,
  TokenRefsResponse
} from './types';

export const PAYMENT_PLATFORMS = {
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
  SAMSUNG_PAY: 'Samsung Pay'
};

export class AcpDigitalWalletDomainClient {
  getCards: nsWebclient.WebapiResourceInstance<
    null,
    {
      cards: CardResponse[];
    }
  > = this.webapiResource({
    method: 'GET',
    path: '/v1/cards'
  });

  getTokenRefs: nsWebclient.WebapiResourceInstance<
    TokenRefsRequest,
    TokenRefsResponse
  > = this.webapiResource({
    method: 'GET',
    path: '/v1/cards/:cardId/token-refs'
  });

  getDigitalCardTokenRefs: nsWebclient.WebapiResourceInstance<
    CardTokenRefsRequest,
    DigitalCardTokenRefsResponse
  > = this.webapiResource({
    method: 'GET',
    path: '/v2/digital-wallet/:wallet_provider/:card_id'
  });

  private _isTokenizedPromises: Array<Promise<boolean>> = [];

  constructor(
    public $window,
    public acpDeviceService: AcpDeviceServiceProvider,
    private acpDigitalWalletGooglePay: AcpDigitalWalletGooglePay,
    private acpDigitalWalletApplePay: AcpDigitalWalletApplePay,
    private webapiResource: nsWebclient.WebapiResourceService,
    private nsDate: nsUtils.NsDateService
  ) {
    'ngInject';
  }

  get isTokenizedPromises(): Array<Promise<boolean>> {
    return this._isTokenizedPromises;
  }

  set isTokenizedPromises(value: Array<Promise<boolean>>) {
    this._isTokenizedPromises = value;
  }

  async getPaymentPlatforms(): Promise<string[]> {
    const platforms: string[] = [];
    if (this.acpDeviceService.isAndroid()) {
      if (await this.isGooglePay()) {
        platforms.push(PAYMENT_PLATFORMS.GOOGLE_PAY);
      }
      if (await this.isSamsungPay()) {
        platforms.push(PAYMENT_PLATFORMS.SAMSUNG_PAY);
      }
    }
    if (this.acpDeviceService.isIos() && (await this.isApplePay())) {
      platforms.push(PAYMENT_PLATFORMS.APPLE_PAY);
    }
    if (platforms.length === 0) {
      throw new Error('Device is not supported');
    }
    return platforms;
  }

  async isApplePay(): Promise<boolean> {
    try {
      return await this.acpDigitalWalletApplePay.isAvailable();
    } catch (e) {
      return false;
    }
  }

  async isGooglePay(): Promise<boolean> {
    try {
      return await this.acpDigitalWalletGooglePay.isAvailable();
    } catch (e) {
      return false;
    }
  }

  async isSamsungPay(): Promise<boolean> {
    try {
      return await this.$window.plugins.samsungpay.isAvailable();
    } catch (e) {
      return false;
    }
  }

  async isTokenized(
    card: CardResponse | DecoratedDigitalCardResponse,
    refs?: TokenRefsResponse
  ): Promise<IsTokenizedResponse> {
    const result: IsTokenizedResponse = {};
    const providers = (await this.isApplePay())
      ? ['applepay']
      : Object.keys(refs);
    for (const provider of providers) {
      result[provider] = await this.isTokenizedForProvider(
        provider,
        card,
        refs ? refs[provider] : null
      );
    }
    return result;
  }

  /**
   * START
   *
   * This block contains duplicate code,
   * but this code can be reused for physical card tokenization,
   * when there are new enhancements for physical card.
   */

  async isDigitalCardTokenized(
    card: DecoratedDigitalCardResponse
  ): Promise<DigitalCardTokenizedResponse> {
    const provider = (await this.isApplePay()) ? 'applepay' : 'googlepay';
    return await this.isDigitalCardTokenizedForProvider(provider, card);
  }

  async isDigitalCardTokenizedForProvider(
    provider: string,
    card: DecoratedDigitalCardResponse
  ): Promise<DigitalCardTokenizedResponse> {
    let apiTokens: Partial<DigitalCardTokenRefsResponse>;
    const response: DigitalCardTokenizedResponse = {} as DigitalCardTokenizedResponse;
    let tokenizedPromises: Array<Promise<DigitalCardTokenRefs>> = [];

    try {
      console.log('Calling Token ref API');
      apiTokens = await this.getDigitalCardTokenRefs({
        wallet_provider: provider,
        card_id: card.id
      });
    } catch (error) {
      console.error('Token refs Error', error);
      apiTokens = {
        token_refs: []
      };
    }

    const apiRefs = apiTokens?.token_refs?.map((t) => t.token_ref_id);

    if (!apiRefs) {
      return response;
    }

    switch (provider) {
      case 'googlepay':
        console.log('Processing Token for GPay');
        try {
          await this.acpDigitalWalletGooglePay.getActiveWallet();
        } catch (e) {
          if (e === 'wallet.null') {
            return response;
          }
        }
        for (const ref of apiTokens?.token_refs) {
          tokenizedPromises.push(
            this.acpDigitalWalletGooglePay
              .isValidToken(card.association, ref.token_ref_id)
              .then((item) => {
                if (!item) return null;
                return ref;
              })
          );
        }
        console.log('Active Token for GPay ', tokenizedPromises);
        await Promise.all(tokenizedPromises).then((result) => {
          tokenizedPromises = [];
          const token = result.find((item) => item !== null);
          if (token) {
            response.isTokenized = true;
            response.tokenizedOn = this.formatDate(token.token_requested_date);
          }
        });
        break;
      case 'applepay':
        console.log('Processing Token for ApplePay');
        // eslint-disable-next-line no-useless-catch
        try {
          const pluginTokens = await this.acpDigitalWalletApplePay.getCardTokens();
          const pluginRef = pluginTokens?.map((t) => t.token_ref_id);
          const activeToken = apiTokens?.token_refs.find((a) =>
            pluginRef?.includes(a.token_ref_id)
          );
          console.log('Active Token for ApplePay ', activeToken);
          if (activeToken) {
            response.isTokenized = true;
            response.tokenizedOn = this.formatDate(
              activeToken.token_requested_date
            );
          } else {
            return response;
          }
        } catch (e) {
          return response;
        }
        break;
      default:
        console.log('Processed Token ', response);
        return response;
    }
    return response;
  }

  /**
   * END
   */

  async isTokenizedForProvider(
    provider: string,
    card: CardResponse | DecoratedDigitalCardResponse,
    refs?: string[]
  ): Promise<boolean> {
    switch (provider) {
      case 'googlepay':
        try {
          await this.acpDigitalWalletGooglePay.getActiveWallet();
        } catch (e) {
          if (e === 'wallet.null') {
            return false;
          }
        }
        for (const ref of refs) {
          this.isTokenizedPromises.push(
            this.acpDigitalWalletGooglePay.isValidToken(card.association, ref)
          );
        }
        break;
      case 'applepay':
        // eslint-disable-next-line no-useless-catch
        try {
          return await this.acpDigitalWalletApplePay.hasCardToken(card.id);
        } catch (e) {
          throw e;
        }
      default:
        return false;
    }
    return Promise.all(this.isTokenizedPromises).then((results: boolean[]) => {
      this.isTokenizedPromises = [];
      return results.some((item: boolean) => {
        return item === true;
      });
    });
  }

  async addToWallet(
    card: CardResponse | DecoratedDigitalCardResponse,
    provider: string,
    cardType: string
  ): Promise<void> {
    switch (provider) {
      case 'googlepay':
        return this.acpDigitalWalletGooglePay.addToWallet(card, cardType);
      case 'applepay':
        return this.acpDigitalWalletApplePay.addToWallet(card, cardType);
    }
  }

  formatDate(date) {
    return new Date(this.nsDate.fromApi(date));
  }
}
