import { CardResponse } from 'components/card-domain';
import { DecoratedDigitalCardResponse } from 'components/digital-card-domain';
import { AcpDeviceServiceProvider } from 'core';
import {
  CardTokenRefsRequest,
  AppleTokenRefsResponse,
  CardData,
  IDigitalWalletApplePay,
  TokenRefs
} from './types';

export class AcpDigitalWalletApplePay {
  api: IDigitalWalletApplePay;
  constructor(
    private $window: ng.IWindowService,
    private acpDeviceService: AcpDeviceServiceProvider,
    private ACP_STORAGE_KEYS: any,
    private nsStorage,
    private webapiResource: nsWebclient.WebapiResourceService
  ) {
    'ngInject';
    if (this.acpDeviceService.isIos()) {
      this.api = this.$window.plugins.applepay;
    }
  }

  getTokenRefs: nsWebclient.WebapiResourceInstance<
    CardTokenRefsRequest,
    AppleTokenRefsResponse
  > = this.webapiResource({
    method: 'GET',
    path: '/v2/digital-wallet/:wallet_provider/:card_id'
  });

  isAvailable(): Promise<boolean> {
    const p: Promise<boolean> = new Promise((resolve, reject) => {
      this.api.isAvailable(resolve, reject);
    });
    return p;
  }

  isTokenized(pseudoPan: string): Promise<boolean> {
    const p: Promise<boolean> = new Promise((resolve, reject) => {
      this.api.isTokenized(pseudoPan, resolve, reject);
    });
    return p;
  }

  getCardTokens(): Promise<TokenRefs[]> {
    const p: Promise<TokenRefs[]> = new Promise((resolve, reject) => {
      this.api.getCardTokens(resolve, reject);
    });
    return p;
  }

  async hasCardToken(cardId: string): Promise<boolean> {
    let apiTokens: Partial<AppleTokenRefsResponse>;

    try {
      apiTokens = await this.getTokenRefs({
        wallet_provider: 'applepay',
        card_id: cardId
      });
    } catch (error) {
      console.error('Token refs Error', error);
      apiTokens = {
        token_refs: []
      };
    }

    const apiRefs = apiTokens?.token_refs?.map((t) => t.token_ref_id);

    if (!apiRefs) {
      return false;
    }

    const pluginTokens = await this.getCardTokens();
    const pluginRef = pluginTokens?.map((t) => t.token_ref_id);

    return apiRefs.find((a) => pluginRef?.includes(a)) ? true : false;
  }

  addToWallet(
    card: CardResponse | DecoratedDigitalCardResponse,
    cardType: string
  ): Promise<void> {
    const cardData: CardData = {
      cardId: card.id,
      name: card.embossedName,
      last4: card.pan_last_4,
      card_type: cardType
    };
    const p: Promise<void> = new Promise(async (resolve, reject) => {
      console.log('Adding to ApplePAY Wallet ', cardData);
      try {
        resolve(await this.addCard(cardData));
      } catch (e) {
        reject(e);
      }
    });
    return p;
  }

  private addCard(cardData: CardData): Promise<void> {
    const headers = this.webapiResource.defaults.headers.common;
    headers['X-NS-Access_Token'] = this.nsStorage.session(
      this.ACP_STORAGE_KEYS.WEB_API_RESOURCE
    );
    const requestUrl = `${this.$window.location.origin}/webapi/v2/digital-wallet/applepay/tokenize`;
    const p: Promise<void> = new Promise((resolve, reject) => {
      this.api.addCard(cardData, headers, requestUrl, resolve, reject);
    });
    return p;
  }
}
