import { CardResponse } from 'components/card-domain';
import { DecoratedDigitalCardResponse } from 'components/digital-card-domain';
import { AcpDeviceServiceProvider } from 'core';
import {
  GetEncrpytedCardDataRequest,
  GetEncrpytedCardDataResponse,
  IDigitalWalletGooglePay
} from './types';

export class AcpDigitalWalletGooglePay {
  api: IDigitalWalletGooglePay;
  getEncryptedCardData: nsWebclient.WebapiResourceInstance<
    GetEncrpytedCardDataRequest,
    GetEncrpytedCardDataResponse
  > = this.webapiResource({
    format: 'json',
    method: 'POST',
    path: '/v2/digital-wallet/:wallet_provider/tokenize'
  });
  constructor(
    private $window: ng.IWindowService,
    private acpDeviceService: AcpDeviceServiceProvider,
    private webapiResource: nsWebclient.WebapiResourceService
  ) {
    'ngInject';
    if (this.acpDeviceService.isAndroid()) {
      this.api = this.$window.plugins.googlepay;
    }
  }

  isValidToken(association: string, refId: string): Promise<boolean> {
    const p: Promise<boolean> = new Promise((resolve, reject) => {
      this.api.isValidToken(resolve, reject, association, refId);
    });
    return p;
  }

  addToWallet(
    card: CardResponse | DecoratedDigitalCardResponse,
    cardType: string
  ): Promise<void> {
    const p: Promise<void> = new Promise(async (resolve, reject) => {
      try {
        const walletId: string = await this.getOrCreateWallet();
        const deviceId: string = await this.getDeviceId();
        const response: GetEncrpytedCardDataResponse = await this.getEncryptedCardData(
          {
            card_id: card.id,
            wallet_id: walletId,
            device_id: deviceId,
            wallet_provider: 'googlepay',
            card_type: cardType
          }
        );
        console.log('Adding to GPAY Wallet ', response);
        resolve(await this.addCard(response));
      } catch (e) {
        reject(e);
      }
    });
    return p;
  }

  isAvailable(): Promise<boolean> {
    const p: Promise<boolean> = new Promise((resolve, reject) => {
      this.api.isAvailable(resolve, reject);
    });
    return p;
  }

  async getOrCreateWallet(): Promise<string> {
    try {
      return await this.getActiveWallet();
    } catch (e) {
      if (e === 'wallet.null') {
        return await this.createWallet();
      }
      return Promise.reject(e);
    }
  }

  getActiveWallet(): Promise<string> {
    const p: Promise<string> = new Promise((resolve, reject) => {
      try {
        this.api.getActiveWallet(resolve, reject);
      } catch (e) {
        reject(e.message);
      }
    });
    return p;
  }

  private createWallet(): Promise<string> {
    const p: Promise<string> = new Promise((resolve, reject) => {
      this.api.createWallet(resolve, reject);
    });
    return p;
  }

  private getDeviceId(): Promise<string> {
    const p: Promise<string> = new Promise((resolve, reject) => {
      this.api.getDeviceId(resolve, reject);
    });
    return p;
  }

  private addCard({
    encrypted_card_data,
    card_association,
    last_digits,
    user_address
  }: GetEncrpytedCardDataResponse): Promise<void> {
    const p: Promise<void> = new Promise((resolve, reject) => {
      this.api.addCard(
        resolve,
        reject,
        encrypted_card_data,
        card_association,
        last_digits,
        user_address.line1,
        user_address.line2,
        'US',
        user_address.city,
        user_address.state,
        user_address.name,
        user_address.phone,
        user_address.zip
      );
    });
    return p;
  }
}
