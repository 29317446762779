import ng from 'angular';
import acpCardDomainModule from 'components/card-domain';
import acpCore from 'core';
import { AcpDigitalWalletApplePay } from './acp-digital-wallet-domain-apple-pay';
import { AcpDigitalWalletDomainClient } from './acp-digital-wallet-domain-client';
import { AcpDigitalWalletGooglePay } from './acp-digital-wallet-domain-google-pay';
import { AcpDigitalWalletDomainModel } from './acp-digital-wallet-domain-model';

export const acpDigitalWalletDomainModule = ng
  .module('acp.components.digital-wallet-domain', [
    acpCore.name,
    acpCardDomainModule.name
  ])
  .service('acpDigitalWalletDomainModel', AcpDigitalWalletDomainModel)
  .service('acpDigitalWalletDomainClient', AcpDigitalWalletDomainClient)
  .service('acpDigitalWalletGooglePay', AcpDigitalWalletGooglePay)
  .service('acpDigitalWalletApplePay', AcpDigitalWalletApplePay);

export * from './acp-digital-wallet-domain-model';
export * from './acp-digital-wallet-domain-client';
export * from './acp-digital-wallet-domain-google-pay';
export * from './acp-digital-wallet-domain-apple-pay';
export * from './types';
