import { CardResponse } from 'components/card-domain';
import { DecoratedDigitalCardResponse } from 'components/digital-card-domain';
import { AcpAnalytics } from 'core';
import { AcpDigitalWalletDomainClient } from './index';
import acpDigitalWalletGeneralErrorTemplate from './templates/acp-digital-wallet-general-error.html';
import {
  DigitalCardTokenizedResponse,
  IsTokenizedResponse,
  TokenRefsResponse
} from './types';

export class AcpDigitalWalletDomainModel {
  private GA_CATEGORY = 'ACP_Digital_Wallets_Push_Provisioning';

  constructor(
    private acpDigitalWalletDomainClient: AcpDigitalWalletDomainClient,
    private nsInPageFlow,
    private acpAnalytics: AcpAnalytics
  ) {
    'ngInject';
  }

  async isTokenized(
    card: CardResponse | DecoratedDigitalCardResponse
  ): Promise<IsTokenizedResponse> {
    const providerRefs: TokenRefsResponse = (await this.acpDigitalWalletDomainClient.isGooglePay())
      ? await this.getTokenRefs(card)
      : null;
    return await this.acpDigitalWalletDomainClient.isTokenized(
      card,
      providerRefs
    );
  }

  async isDigitalCardTokenized(
    card: DecoratedDigitalCardResponse
  ): Promise<DigitalCardTokenizedResponse> {
    return await this.acpDigitalWalletDomainClient.isDigitalCardTokenized(card);
  }

  async addToWallet(
    card: CardResponse | DecoratedDigitalCardResponse,
    provider: string,
    cardType: 'digital' | undefined = undefined
  ): Promise<void> {
    this.acpAnalytics.sendEvent(
      this.GA_CATEGORY,
      'Add_To_Digital_Wallet',
      'acp-Cards-detail',
      provider
    );
    return await this.acpDigitalWalletDomainClient.addToWallet(
      card,
      provider,
      cardType
    );
  }

  getPaymentPlatformsFormatted(): Promise<string> {
    return this.acpDigitalWalletDomainClient
      .getPaymentPlatforms()
      .then((platforms) => {
        return platforms.join(' and ');
      });
  }

  async isApplePay(): Promise<boolean> {
    return this.acpDigitalWalletDomainClient.isApplePay();
  }

  isGooglePay(): Promise<boolean> {
    return this.acpDigitalWalletDomainClient.isGooglePay();
  }

  isSamsungPay(): Promise<boolean> {
    return this.acpDigitalWalletDomainClient.isSamsungPay();
  }

  error(e: string): void {
    this.acpAnalytics.sendEvent(
      this.GA_CATEGORY,
      'Error',
      'acp-Push--Provisioning-Error'
    );
    const self = this;
    const config = {
      template: acpDigitalWalletGeneralErrorTemplate,
      controller: ($scope) => {
        'ngInject';
        $scope.close = this.nsInPageFlow.close;
        async function init() {
          $scope.paymentPlatform = await self.getPaymentPlatformsFormatted();
        }
        init();
      }
    };
    if (this.nsInPageFlow.isOpen()) {
      this.nsInPageFlow.push(config);
    } else {
      this.nsInPageFlow.open(config);
    }
  }

  getCards(): Promise<{
    cards: CardResponse[];
  }> {
    return this.acpDigitalWalletDomainClient.getCards();
  }

  private getTokenRefs(
    card: CardResponse | DecoratedDigitalCardResponse
  ): Promise<TokenRefsResponse> {
    return this.acpDigitalWalletDomainClient.getTokenRefs({
      cardId: card.id
    });
  }
}
